$(document).ready(function () {
  // burger menu toggle
  const headerEl = document.querySelector('header#header');
  const skipNavItems = headerEl.querySelectorAll('.skip-nav a');
  const navToggle = headerEl.querySelector('button.navigation-toggle');
  const metaNav = headerEl.querySelector('.navigation-meta');
  const mainNav = headerEl.querySelector('.navigation-main');
  const logoNavAnchor = headerEl.querySelector('.navigation-logo a');
  const searchToggleMobile = headerEl.querySelector('.navigation-mobile .search__form-submit');
  const mainMenuItemsFirstOrder = mainNav.querySelectorAll('.navigation-item > a, .navigation-item > button');
  const metaNavLinks = metaNav.querySelectorAll('a');

  navToggle.addEventListener('click', () => {
    if (navToggle.getAttribute('aria-expanded') === 'true') {
      closeSearch();
      closeBurgerMenu();
    } else {
      closeSearch();
      closeSubmenus();
      navToggle.setAttribute('aria-expanded', 'true');
      document.body.classList.add('nav-open');
      headerEl.style.setProperty('--meta-navigation-height', metaNav.clientHeight + 'px');
      headerEl.style.setProperty('--main-navigation-logo-height', logoNavAnchor.clientHeight + 'px');
      headerEl.style.setProperty('--main-navigation-height', mainNav.clientHeight + 'px');
      document.querySelector('main#content').setAttribute('inert', '');
      document.querySelector('footer#footer').setAttribute('inert', '');

      // set correct taborder on burger nav
      skipNavItems.forEach((item, i) => {
        item.setAttribute('tabindex', `${i + 1}`);
      });

      logoNavAnchor.setAttribute('tabindex', `${skipNavItems.length + 1}`);
      searchToggleMobile.setAttribute('tabindex', `${skipNavItems.length + 2}`);
      navToggle.setAttribute('tabindex', `${skipNavItems.length + 3}`);
      mainMenuItemsFirstOrder.forEach((item, i) => {
        item.setAttribute('tabindex', `${i + skipNavItems.length + 4}`);
      });

      metaNavLinks.forEach((item, i) => {
        item.setAttribute('tabindex', `${i + mainMenuItemsFirstOrder.length + skipNavItems.length + 4}`);
      });

      // set inert to all items
      if (document.body.classList.contains('nav-open')) {
        setInert();
      }

      // remove inert on mainNav and metaNav elements
      mainMenuItemsFirstOrder.forEach((item) => {
        item.removeAttribute('inert');
      });
      metaNavLinks.forEach((item) => {
        item.removeAttribute('inert');
      });
    }
  });

  //subnavigation of mainnavigation toggle
  const navigationItemsWithSubmenu = document.querySelectorAll('.navigation-item.has-submenu');
  navigationItemsWithSubmenu.forEach((navigationItem, index) => {
    const submenuTogglers = navigationItem.querySelectorAll('.submenu-toggle');
    submenuTogglers.forEach((submenuToggler) => {
      const submenuItem = submenuToggler.parentElement;
      const submenu = submenuItem.parentElement;
      const submenuMenu = submenuToggler?.nextElementSibling;
      const submenuClose = submenuItem?.querySelector('button.submenu-close');
      const submenuHeight = submenuMenu.clientHeight;

      submenuToggler?.addEventListener('click', (event) => {
        if (submenuToggler.getAttribute('aria-expanded') === 'true') {
          submenuToggler.setAttribute('aria-expanded', 'false');
          submenuMenu.classList.remove('open');
        } else {
          submenuToggler.setAttribute('aria-expanded', 'true');
          submenuMenu.classList.add('open');
        }

        if (document.body.classList.contains('nav-open')) {
          setInert();
          removeInert(submenuMenu);
        }

        if (submenuItem.classList.contains('navigation-item')) {
          closeSubmenus(submenuItem);
          closeSubmenus(submenuItem, true);
        } else {
          closeSubmenus(submenuItem, true);
          if (submenuToggler.getAttribute('aria-expanded') === 'true') {
            submenu.style.setProperty('--submenu-min-height', submenuHeight + 'px');
          } else {
            submenu.style.setProperty('--submenu-min-height', 'auto');
          }
        }
      });

      submenuClose?.addEventListener('click', () => {
        submenuToggler.setAttribute('aria-expanded', 'false');
        submenuMenu.classList.remove('open');

        if (document.body.classList.contains('nav-open')) {
          setInert();
          if (document.querySelector('.submenu.open')) {
            removeInert(document.querySelector('.submenu.open'), submenuToggler);
          } else {
            removeInert(document.querySelector('.navigation-main__nav'), submenuToggler);
          }
        }
      });
    });
  });

  // close submenus, all or all except given
  function closeSubmenus(exceptThisOne = null, secondLevel = false) {
    const submenus = secondLevel ? document.querySelectorAll('.submenu-item.has-submenu') : navigationItemsWithSubmenu;

    submenus.forEach((submenuItem) => {
      if (submenuItem === exceptThisOne) return;

      const submenuToggles = submenuItem.querySelectorAll('.submenu-toggle');
      submenuToggles.forEach((submenuToggle) => {
        const submenu = submenuToggle.nextElementSibling;
        const submenuMenu = submenu.querySelector('.submenu-menu');
        submenuToggle.setAttribute('aria-expanded', 'false');
        submenu.classList.remove('open');
        submenuMenu.style.setProperty('--submenu-min-height', 'auto');
      });
    });
  }

  function closeSearch() {
    const expandableSearchForms = document.querySelectorAll('.search__form--expandable');
    const EXPANDABLE_FORM_ACTIVE_CLASS = 'search__form--expanded';

    expandableSearchForms.forEach((form) => {
      if (form.classList.contains(EXPANDABLE_FORM_ACTIVE_CLASS)) {
        form.classList.remove(EXPANDABLE_FORM_ACTIVE_CLASS);
        form.querySelector('.search__form-submit').focus();
      }
    });
  }

  function closeBurgerMenu() {
    const navToggle = document.querySelector('button.navigation-toggle');
    navToggle.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('nav-open');
    document.querySelector('main#content').removeAttribute('inert');
    document.querySelector('footer#footer').removeAttribute('inert');
  }

  function setInert() {
    const navItems = document.querySelectorAll(
      '.nav-open header#header a:not(.skip-nav__link):not(:has(img)), .nav-open header#header button:not(.search__form-submit):not(.navigation-toggle)',
    );
    navItems.forEach((item) => {
      item.setAttribute('inert', '');
    });
  }

  function removeInert(menu, elToFocus) {
    // remove inert on last submenu level
    if (menu.classList.contains('submenu-last-level')) {
      menu
        .querySelectorAll(
          '.submenu-last-level .submenu-toggle, .submenu-last-level .submenu-link, .submenu-last-level .submenu-close',
        )
        .forEach((item) => {
          item.removeAttribute('inert');
        });

      // focus element in newly opened submenu
      setTimeout(() => {
        if (elToFocus) {
          elToFocus.focus();
        } else {
          menu.querySelector('.submenu-last-level .submenu-link').focus();
        }
      }, 300);
    }

    // remove inert on 2nd submenu level
    if (!menu.classList.contains('submenu-last-level') && menu.classList.contains('submenu')) {
      menu
        .querySelectorAll(
          ':scope > div > ul > li > .submenu-toggle, :scope > div > ul > li > .submenu-link, :scope > div > .submenu-close',
        )
        .forEach((item) => {
          item.removeAttribute('inert');
        });

      // focus element in newly opened submenu
      setTimeout(() => {
        if (elToFocus) {
          elToFocus.focus();
        } else {
          menu.querySelector(':scope > div > ul > li > .submenu-link').focus();
        }
      }, 300);
    }

    // remove inert on main menu level
    if (menu.classList.contains('navigation-main__nav')) {
      menu.querySelectorAll('.navigation-item > a, .navigation-item > button').forEach((item) => {
        item.removeAttribute('inert');
      });

      const metaNavLinks = document.querySelectorAll('.nav-open header#header .navigation-meta-link');
      metaNavLinks.forEach((item) => {
        item.removeAttribute('inert');
      });

      // focus element in newly opened submenu
      setTimeout(() => {
        if (elToFocus) {
          elToFocus.focus();
        } else {
          menu.querySelector('.navigation-item > a').focus();
        }
      }, 300);
    }
  }

  // close all header menus on window resize
  window.onresize = () => {
    closeSubmenus();
    closeBurgerMenu();
  };

  // close navigation and search on ESC or on click outside of nav
  document.addEventListener('keydown', (event) => {
    const openNav = document.querySelector('.submenu.open');
    const openBurgerMenu = document.querySelector('.navigation-toggle[aria-expanded="true"]');
    if (event.key === 'Escape') {
      openNav?.previousElementSibling?.click();
      openBurgerMenu?.click();
    }
  });

  document.addEventListener('click', (event) => {
    if (event.target instanceof Element) {
      if (!document.getElementById('header')?.contains(event.target)) {
        const openNav = document.querySelector('.submenu.open');
        const openBurgerMenu = document.querySelector('.navigation-toggle[aria-expanded="true"]');
        const expandableSearchForms = document.querySelectorAll('.search__form--expandable');
        const EXPANDABLE_FORM_ACTIVE_CLASS = 'search__form--expanded';

        openNav?.previousElementSibling?.click();
        openBurgerMenu?.click();
        expandableSearchForms.forEach((form) => {
          const formActionValue = form.getAttribute('action');
          const searchForms = Array.from(document.querySelectorAll(`form[action='${formActionValue}']`));
          searchForms.forEach((searchForm) => {
            searchForm.classList.remove(EXPANDABLE_FORM_ACTIVE_CLASS);
          });
        });
      }
    }
  });

  var oldsrc;
  $('.ce-border img').hover(
    function () {
      var alt = $(this).attr('alt');
      if (alt.length > 0) {
        oldsrc = $(this).attr('src');
        $(this).attr('src', alt);
      }
    },
    function () {
      if (oldsrc.length > 0) {
        $(this).attr('src', oldsrc);
        oldsrc = '';
      }
    },
  );

  $('#site-select').on('change', function () {
    var url = $(this).val(); // get selected value
    if (url) {
      // require a URL
      window.location = url; // redirect
    }
    return false;
  });

  var event = new Event('projectLoaded');
  window.dispatchEvent(event);

  setTimeout(function () {
    if (window.location.href.includes('medikcal')) {
      $('#medikcal-cookie-extension').text('Cookie-Hinweis');
      $('#medikcal-cookie-extension').css('padding', '5px 16px');
    }
  }, 250);

  // $.calendar.init();

  // google maps
  // change address in ce based on experts recordset
  // due to race-conditions only appearing in safari, we need to check for the correct
  // iframe url and replace it afterwards.
  const _address = document.getElementById('expert-detail')?.dataset?.mapAddress;
  if (_address) {
    const _maps = document.querySelectorAll('.frame-type-experts_detail + .frame-type-tx_supi_maps [data-map-address]');

    _maps.forEach((elem) => {
      elem.dataset['mapAddress'] = _address;
      const figcaption = elem.querySelector('figcaption address');

      if (figcaption) {
        figcaption.innerText = _address;
      }
    });

    const _iframe = document.querySelectorAll('iframe[src*="maps.google.com/maps?q=&"]');
    _iframe.forEach((iframe) => {
      iframe.src = iframe.src.replace('maps?q=&', 'maps?q=' + _address + '&');
    });
  }

  // when a form field has the data-required attribute, you can set per js the required attribute on the
  // field called in the data-required attribute
  document.querySelectorAll('[data-required]').forEach((elem) => {
    const _target = document.querySelector('[name$="[' + elem.dataset.required + ']"]');
    const _label = _target.parentElement.querySelector('label');

    const _asterisk = document.createElement('span');
    _asterisk.innerText = ' *';
    _asterisk.classList.add('required');
    _asterisk.setAttribute('aria-hidden', 'true');

    elem.addEventListener('change', function (e) {
      if (this.checked) {
        _target.setAttribute('required', 'required');
        _label.append(_asterisk);
      } else {
        _target.removeAttribute('required');
        _label.querySelector('span.required').remove();
      }
    });
  });
});

$(function initFormValidation() {
  if ($('.js-spam-error').length) {
    $(document).scrollTop($('.js-spam-error').offset().top - 100);
  }

  Array.from(document.querySelectorAll('.needs-validation')).forEach((form) => {
    form.addEventListener('submit', (event) => {
      if (!form.checkValidity()) {
        event.stopPropagation();
        event.preventDefault();
        form.querySelector(':invalid').focus();
      }
      form.classList.add('was-validated');
    });

    Array.from(form.elements).forEach((element) => {
      element.addEventListener('input', () => {
        if (element.validity.valid) {
          element.removeAttribute('aria-invalid');
        } else {
          element.setAttribute('aria-invalid', 'true');
        }
      });
    });
  });

  Array.from(document.querySelectorAll('[data-required-group]'))
    .group((input) => input.dataset.requiredGroup)
    .forEach((inputGroup) => {
      inputGroup.forEach((input) => {
        input.required = true;
        input.addEventListener('input', () => {
          const isInputValid = input.checkValidity();
          inputGroup
            .filter((el) => el !== input)
            .forEach((el) => {
              el.required = !isInputValid;
            });
        });
      });
    });
});

$(function initSearchForms() {
  const CUSTOM_SELECT_ACTIVE_CLASS = 'search__select-options--active';
  const EXPANDABLE_FORM_ACTIVE_CLASS = 'search__form--expanded';

  const customSelectLabels = Array.from(document.querySelectorAll('.search__select-label--custom'));
  const nativeSelects = Array.from(document.querySelectorAll('.search__form .search__select-input'));
  const expandableSearchForms = Array.from(document.querySelectorAll('.search__form--expandable'));

  nativeSelects.forEach((select) => {
    select.addEventListener('change', (event) => {
      if (!select.value) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      goToLocation(select.value);
    });
  });

  customSelectLabels.forEach((select) => {
    const dropdown = select.nextElementSibling;
    const options = Array.from(dropdown.querySelectorAll('.search__select-option'));

    select.addEventListener('click', (event) => {
      event.preventDefault();
      dropdown.classList.toggle(CUSTOM_SELECT_ACTIVE_CLASS);
    });
    select.addEventListener('keydown', (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
        dropdown.classList.add(CUSTOM_SELECT_ACTIVE_CLASS);
        dropdown.firstElementChild.focus();
      }
    });
    select.parentElement.addEventListener('focusout', (event) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        dropdown.classList.remove(CUSTOM_SELECT_ACTIVE_CLASS);
      }
    });

    options.forEach((option) => {
      option.addEventListener('keydown', (event) => {
        if (
          ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'Space', 'Enter', 'Tab', 'Escape'].includes(event.code)
        ) {
          event.preventDefault();
        }
        switch (event.key) {
          case 'ArrowDown':
          case 'ArrowRight':
            option.nextElementSibling?.focus();
            break;
          case 'ArrowUp':
          case 'ArrowLeft':
            option.previousElementSibling?.focus();
            break;
          case 'Enter':
            goToLocation(option.dataset.url);
          case 'Tab':
          case 'Escape':
            select.focus();
            dropdown.classList.remove(CUSTOM_SELECT_ACTIVE_CLASS);
            break;
        }
      });

      option.addEventListener('click', () => {
        dropdown.classList.remove(CUSTOM_SELECT_ACTIVE_CLASS);
        goToLocation(option.dataset.url);
      });
    });
  });

  expandableSearchForms.forEach((form) => {
    form.noValidate = true;

    form.addEventListener('submit', (event) => {
      if (!form.checkValidity() || !form.classList.contains(EXPANDABLE_FORM_ACTIVE_CLASS)) {
        event.preventDefault();
        const formActionValue = form.getAttribute('action');
        const searchForms = Array.from(document.querySelectorAll(`form[action='${formActionValue}']`));
        searchForms.forEach((searchForm) => {
          if (searchForm.classList.toggle(EXPANDABLE_FORM_ACTIVE_CLASS)) {
            const navToggle = document.querySelector('button.navigation-toggle');
            const mainNav = document.querySelector('.navigation-main');
            const searchItem = document.querySelector('.search-item');
            navToggle.setAttribute('aria-expanded', 'false');
            document.body.classList.remove('nav-open');
            searchForm.elements[0].focus();
            searchItem.style.setProperty('--navigation-main-width', mainNav.clientWidth + 'px');
          } else {
            searchForm.querySelector('.search__form-submit').focus();
          }
        });
      }
    });

    form.addEventListener('focusout', (event) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        form.classList.remove(EXPANDABLE_FORM_ACTIVE_CLASS);
      }
    });

    form.querySelector('.search__suggestions')?.addEventListener(
      'pointerdown',
      (event) => {
        event.stopPropagation();
        event.preventDefault();
      },
      { capture: true },
    );

    form.addEventListener('keydown', (event) => {
      if (event.code === 'Escape') {
        event.preventDefault();
        const formActionValue = form.getAttribute('action');
        const searchForms = Array.from(document.querySelectorAll(`form[action='${formActionValue}']`));
        searchForms.forEach((searchForm) => {
          searchForm.classList.remove(EXPANDABLE_FORM_ACTIVE_CLASS);
          searchForm.querySelector('.search__form-submit').focus();
        });
      }
    });
  });

  function goToLocation(uri) {
    const a = document.createElement('a');
    a.href = uri;
    a.click();
  }
});

Array.prototype.group = function (cb) {
  const groups = this.reduce((output, value) => {
    const groupIdentifier = cb(value);
    if (!output.hasOwnProperty(groupIdentifier)) {
      output[groupIdentifier] = [];
    }
    output[groupIdentifier].push(value);
    return output;
  }, {});
  return Object.values(groups);
};
