/**
 * this event listener changes the podigee data-configuration url to a specific episode, if one is set in the backend
 */
document.addEventListener('supiInitStart', (e) => {
  const episodes = document.querySelectorAll('[data-supi-service-episode]');

  episodes.forEach((episode) => {
    try {
      const episodeUrl = episode.dataset.supiServiceEpisode;
      const data = episode.dataset;
      const supiServiceAttr = JSON.parse(data.supiServiceAttr);
      supiServiceAttr['data-configuration'] = episodeUrl + '/embed?context=external&theme=default';

      episode.dataset.supiServiceAttr = JSON.stringify(supiServiceAttr);
    } catch {
      console.log('no episode found');
    }
  });
});
