(function ($) {
  'use strict';

  $.calendar = new Object({
    init: function () {
      let selectedFiltersWrapper;
      let selectedFilters;

      let _uid = $('[data-calendar-uid]').data('calendarUid');
      let _pid = $('[data-calendar-pid]').data('calendarPid');
      let _language = $('[data-calendar-language]').data('calendarLanguage');
      let _view = $('[data-calendar-view]').data('calendarView');
      let _storage = $('[data-calendar-storage]').data('calendarStorage');
      let _calendarEl = $('#calendar' + _uid);
      let _filtersActive = $('#categoryFilter' + _uid).length;
      let _ajaxUri = '/index.php?id=' + _pid + '&type=1573738558&storage=' + _storage;

      let calendar = new FullCalendar.Calendar(_calendarEl.get(0), {
        firstDay: 1,
        locale: _language,
        plugins: ['dayGrid', 'timeGrid'],
        // defaultView: _view,
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        eventLimit: true,
        navLinks: true,
        fixedWeekCount: false,
        events: {
          url: _ajaxUri,
          failure: function (request, status, error) {
            console.log(request);
            console.log(status);
            console.log(error);
          },
        },
        eventRender: function (item) {
          alert('foobar');
          console.log(item);
          $(item.el).prop('title', item.event.extendedProps.abstract);

          if (filtersActive) {
            var selectedCategories = $('#categoryFilter' + _uid + ' input:checked')
              .map(function () {
                return $(this).val();
              })
              .get();

            if (selectedCategories.length > 0) {
              $(item.el).addClass('d-none');

              selectedCategories.forEach(function (selectedVal) {
                if ($(item.el).hasClass(selectedVal)) {
                  $(item.el).removeClass('d-none');
                }
              });
            }
          }
        },
      });

      console.log(calendar);

      calendar.render();
    },
  });
})(jQuery);
