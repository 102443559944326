$(document).ready(function () {
  var $pswp = $('.pswp')[0];
  var image = [];
  $('.ce-gallery').each(function () {
    var $pic = $(this),
      getItems = function () {
        var items = [];
        $pic.find('a[data-size]').each(function () {
          var $href = $(this).attr('href'),
            $title = $(this).find('img').attr('title'),
            $size = $(this).data('size').split('x'),
            $width = $size[0].replace('m', '').replace('c', ''),
            $height = $width * ($(this).find('img').attr('height') / $(this).find('img').attr('width'));

          if ($title === undefined) $title = $(this).find('img').attr('alt');

          var item = {
            src: $href,
            w: $width,
            h: $height,
            title: $title,
          };

          items.push(item);
        });
        return items;
      };

    var items = getItems();

    $.each(items, function (index, value) {
      image[index] = new Image();
      image[index].src = value['src'];
    });

    $pic.on('click', 'a[data-size]', function (event) {
      event.preventDefault();
      var $index = $pic.find('a[data-size]').index($(this));
      var options = {
        index: $index,
        bgOpacity: 0.9,
        showHideOpacity: true,
      };

      var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
      lightBox.init();
    });
  });

  $('.news-single').each(function () {
    var $pic = $(this),
      getItems = function () {
        var items = [];
        $pic.find('a.lightbox[data-size]').each(function () {
          var $href = $(this).attr('href'),
            $title = $(this).find('img').attr('title'),
            $size = $(this).data('size').split('x'),
            $width = $size[0].replace('m', '').replace('c', ''),
            $height = $size[1].replace('m', '').replace('c', '');
          //$height = $width * ($(this).find('img').attr('height') / $(this).find('img').attr('width'));

          if ($title === undefined) $title = $(this).find('img').attr('alt');

          var item = {
            src: $href,
            w: $width,
            h: $height,
            title: $title,
          };

          items.push(item);
        });
        return items;
      };

    var items = getItems();

    $.each(items, function (index, value) {
      image[index] = new Image();
      image[index].src = value['src'];
    });

    $pic.on('click', 'a.lightbox[data-size]', function (event) {
      event.preventDefault();
      var $index = $pic.find('a.lightbox[data-size]').index($(this));
      var options = {
        index: $index,
        bgOpacity: 0.8,
        showHideOpacity: true,
        loop: false,
      };

      var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
      lightBox.init();
    });
  });

  $('.image-slider').each(function () {
    function getDimensions(url) {
      var res = $.Deferred(),
        tempImg = new Image();

      $(tempImg)
        .attr('src', url)
        .on('load', function () {
          var dimensions = {
            w: this.width,
            h: this.height,
          };
          res.resolve(dimensions);
        });
      tempImg.remove();
      return res;
    }

    var $pic = $(this),
      $imageMap = $pic.find('.splide__slide:not(.splide__slide--clone) a.lightbox');
    (getItems = function () {
      var items = [];
      $imageMap.each(function () {
        var $href = $(this).attr('href'),
          $title = $(this).find('img').attr('title');

        if ($title === undefined) $title = $(this).find('img').attr('alt');

        getDimensions($href).done((e) => {
          var $width = e.w,
            $height = e.h,
            item = {
              src: $href,
              w: $width,
              h: $height,
              title: $title,
            };

          items.push(item);
        });
      });

      return items;
    }),
      (pswItems = getItems());

    $.each(pswItems, function (index, value) {
      image[index] = new Image();
      image[index].src = value['src'];
    });

    $pic.on('click', 'a.lightbox', function (event) {
      event.preventDefault();
      var $index = $imageMap.index($(this));

      var options = {
        index: $index,
        bgOpacity: 0.8,
        showHideOpacity: true,
      };

      var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, pswItems, options);

      lightBox.init();
    });
  });
});
